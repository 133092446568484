.projects-content {
  @include grid-container(all);
  .page-items {
    &:first-child {
      .page-item:first-child {
        // NOTE this matches the font size of the 'savio parsons' text
        margin-top: inset($negative: true);
        // padding-top: calc(88vh - #{$rhythm * 2});
        position: relative;
        @include scalable-property(top, 20, 15);
        @include phone {
          padding-top: 50vh;
        }
        @include tablet-plus {
          padding-top: 78vh;
        }
      }
    }
    .page-item {
      img {
        // border: 1px solid red;
        transition: filter 2s, opacity 2s;
        opacity: 1;
        filter: grayscale(0%);
        .state-pattern-visible & {
          opacity: .6;
          filter: grayscale(100%);
        }
      }
      .caption {
        margin-top: $rhythm * .5;
        text-align: right;
        @include set-font(00);
        text-transform: uppercase;
        transition: opacity 2s;
        opacity: 1;
        p {
          margin-bottom: 0;
        }
        .state-pattern-visible & {
          opacity: 0;
        }
      }
      @include phone {
        // @.page-item
        margin-bottom: 60vh;
        &.position-left {
          width: 75%;
        }
        &.position-right {
          margin-left: 25%;
          width: 75%;
        }
        .caption {
          margin-left: 20%;
        }
      }
      @include tablet-plus {
        // @.page-item
        margin-bottom: 60vh;
        &.position-left {
          width: 50%;
        }
        &.position-right {
          margin-left: 50%;
          width: 50%;
        }
        &.position-left-wide {
          width: 66.6%;
        }
        &.position-right-wide {
          margin-left: 33.3%;
          width: 66.6%;
        }
        .caption {
          margin-left: 50%;
        }
      }
    }
  }
}
