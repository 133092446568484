$inputs: '[type="text"], [type="search"], [type="password"], [type="email"], [type="number"], textarea';
$focus-border-color: lighten($black, 80);

.messages {
  margin: 0 0 $rhythm * 2;
  list-style: none;
  padding: 0;
  // font-weight: 500;
  // font-family: 'heldane-web';
  max-width: 450px;
  // border: 1px solid red;
  // padding: 10px;
  @include font(16);

  .info {
    // color: $green;
  }
  .error {
    color: $red;
  }
}

.form-field {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 450px;

  label {
    display: block;
    font-size: .8em;
    margin-bottom: .2em;

    em {
      font-style: normal;
    }
  }
  // &.required {
  //   label::after {
  //     content: '*';
  //   }
  // }
  .help {
    font-size: .8em;
    margin-top: .2em;
  }

  ul {
    // assume this is a RadioSelect
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 20px 0 0;
      .radio-mask {
        margin-right: 10px;
      }
    }
  }
}

// .checkbox-field {
//   label {
//     display: inline-block;
//     input {
//       font-size: 1rem;
//     }
//   }
// }

.form-buttons {
  margin-bottom: $rhythm;

  a,
  button,
  [type="submit"] {
    border-radius: 0;
    display: inline-block;
    background: transparent;
    color: $black;
    font-size: .8em;
    text-transform: uppercase;
    padding: 10px 15px;
    border: 1px solid $black !important;
    line-height: 1em;
    margin-bottom: 4px;
  }
}


#{$inputs}, .select-mask {
  transition:300ms border-color;
  background: $almost-white;
  color: $black;
  // border: 1px solid $black;
  border: 0px;
  display: inline-block;
  border-radius: 0 !important;
  @include phone {
    width: 100%;
  }
  @include tablet-plus {
    width: calc(100% - 30px);
  }
}

#{$inputs}, select {
  @include phone {
    font-size: 16px !important;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $almost-white inset;
}

.eyebrow input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $white inset;
}

// [type="search"] {
//   -webkit-box-shadow: 0 0 0 1000px $white inset;
// }

#{$inputs}, .select-mask  span {
  padding: 10px 15px;
  @include phone {
    width: 100%;
  }
  @include tablet-plus {
    width: calc(100% - 30px);
  }
  white-space: nowrap;
}

#{$inputs} {
  -webkit-appearance: none;

  // &:focus {
  //   outline: none;
  //   border-color: $focus-border-color;
  // }
}

textarea {
  height: 150px;
}

select {
  // Assumes all selects will be masked
  // visibility: hidden;
  // width: 100%;
  // margin-bottom: 11px;
}

.select-mask {
  select {
    cursor: pointer;
    visibility: visible;
  }
  span::after {
    content: 'â¼';
    font-size: .6rem;
    line-height: 1em;
    font-family: arial, helvetica;
  }
}

.select-mask:not(.naked-select) {
  // border: 1px solid $black;

  span {
    display: block;
    padding: 10px 30px 10px 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span::after {
    position: absolute;
    right: 11px;
    top: 15px;
    margin: .4em 0 0 20px;
  }

  // &.focused {
  //   border-color: $focus-border-color;
  // }
}

.select-mask.naked-select {
  border: none;
  padding: 0;
  background: none;
  span {
    padding: 0;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span::after {
    display: inline-block;
    position: relative;
    margin: -.4em 0 0 10px;
  }
}

.radio-field,
.checkbox-field {
  position: relative;
  display: inline-block;
  margin-top: $rhythm * .5;
  margin-bottom: $rhythm * .5;
  padding-left: $rhythm * .8;
  width: 50%;
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  input[type="radio"] + .fake,
  input[type="checkbox"] + .fake {
    position: absolute;
    top: .4em;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 1px solid $black;
  }
  input[type="radio"] + .fake {
    border-radius: 50%;
  }
  input[type="radio"]:checked + .fake,
  input[type="checkbox"]:checked + .fake {
     background-color:$black;
  }
}

form {
  .error,
  .error-warning {
    color: $red;
  }
}

.errorlist {
  list-style: none;
  color: $red;
  padding: 0;
  margin: 15px 0 0;
  max-width: 22em;

  li {
    margin: 0 0 5px;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=file] {
  font-size: .8em;
}

input[type=file]::-webkit-file-upload-button {
  border: 1px solid $black;
  padding: 10px 15px;
  background: none;
}

// #{$inputs}, .select-mask {
//   max-width: 500px;
// }

// input[type="submit"] {
//   margin-bottom: $rhythm;
//   display: block;
//   padding: $rhythm * .75 $rhythm;
//   border: 0;
//   // background: $black;
//   // color: $white;
//   font-weight: 500;
//   text-transform: uppercase;
//   min-width: 200px;
//   &:hover,
//   &:focus {
//     opacity: .9;
//   }
// }
