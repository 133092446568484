$svg-width: 1004;
$svg-height: 2009;
$svg-block-height: 455;
$svg-height-including-space: 2124.9; // @  * 1.0577
$svg-pattern-height: ($svg-height-including-space * 2);
$svg-space: ($svg-pattern-height - ($svg-block-height * 6)) / 5;

$letter-colour: $almost-white;
$block-height: ($svg-block-height / $svg-height) * 100%; // from svg
$line-width-phone: 5px;
$line-width-tablet-plus: 6px;

@function letter-gap($letter-height) {
  @return ($svg-space / $svg-pattern-height) * $letter-height;
}

// .patterns {
//
// }

[class^='pattern-'] {
  z-index: 1;
  @include fit-parent(fixed);
  @include grid-container($orientation: all);
  // top: inset();
  // left: inset();
  // right: inset();
  // height: calc(98vh - 2vw); // this is 100% minus the inset()
  &:not(.pattern-404) {
    transition: opacity 400ms;
    opacity: 0;
    transform: translateZ(0); // hardware accelerated
    &.current-pattern {
      // NOTE we only show/hide the current pattern with opacity,
      //      because we use it as a click handler
      z-index: 100; // this should be above everything else (except footer.)
      .state-pattern-visible & {
        opacity: 1;
      }
    }
  }
  &.pattern-404 {
    z-index: 100;
  }
  .internal {
    @include fit-parent;
  }
  [class^='letter-'] {
    transform: translateZ(0); // hardware accelerated
  }
  @include phone {
    // @[class^='pattern-']
    &:not(.pattern-404) {
      // background: rgba(orange, .3);
      &[style*='height'] {
        bottom: auto;
        transition: opacity 400ms, height 200ms;
        max-height: calc(98vh - 2vw); // this is 100% minus the inset()
      }
    }
    // .internal {
    //   margin-bottom: $rhythm * 1.2;
    // }
  }
  @include tablet-plus {
    // @[class^='pattern-']
    // &:not(.pattern-404) {
    //   .internal {
    //     margin-bottom: $rhythm * 2;
    //   }
    // }
  }
}

.pattern-01 {
  $min: 5%;
  $max: 95%;
  $margin-scalable: 20;
  $margin-set: 15;
  .internal {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
  }
  [class^='letter-'] {
     height: #{50% - letter-gap(50)};
     max-width: $max !important;
     min-width: $min  !important;
  }
  // inititial position
  .letter-s {
    width: $min;
    // set alignment (right)
    margin-left: auto;
    margin-right: 0;
    // 'overhang'
    [class^='polygon-'],
    .path-01 {
      margin-left: -$min;
    }
  }
  .letter-p {
    width: $max;
    // set alignment (left)
    margin-left: 0;
    margin-right: auto;
    // 'overhang'
    [class^='polygon-'],
    .path-02 {
      margin-right: -$min;
    }
  }
  @include desktop-plus {
    $desktop-plus-min: 2%;
    $desktop-plus-max: 98%;
    [class^='letter-'] {
       max-width: $desktop-plus-max !important;
       min-width: $desktop-plus-min  !important;
    }
    // inititial position
    .letter-s {
      width: $desktop-plus-min;
      // 'overhang'
      [class^='polygon-'],
      .path-01 {
        margin-left: -$desktop-plus-min;
      }
    }
    .letter-p {
      width: $desktop-plus-max;
      // 'overhang'
      [class^='polygon-'],
      .path-02 {
        margin-right: -$desktop-plus-min;
      }
    }
  }
}

.pattern-02 {
  // bottom: inset($multiple: 2);
  $min: 3%;
  $max: 97%;
  .internal {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
  }
  [class^='letter-'] {
    margin-left: 60%;
    max-height: $max !important;
    min-height: $min !important;
    &:first-child {
      margin-bottom: inset();
    }
  }
  // inititial position
  .letter-s {
    height: $min;
  }
  .letter-p {
    height: $max;
  }
}

.pattern-03 {
  $min: 5%;
  $max: 95%;
  .internal {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
  }
  [class^='letter-'] {
     max-width: $max !important;
     min-width: $min  !important;
  }
  // inititial position
  .letter-s {
    width: $min;
    // set alignment (right)
    margin-left: 0;
    margin-right: auto;

  }
  .letter-p {
    width: $max;
    // set alignment (left)
    margin-left: auto;
    margin-right: 0;
  }
  @include phone {
    // @.pattern-03
    [class^='letter-'] {
      height: #{35% - letter-gap(35)};
    }
    .spacer {
      height: #{(letter-gap(35) * 2) + 0%};
    }
  }
  @include tablet-plus {
    // @.pattern-03
    [class^='letter-'] {
      height: #{30% - letter-gap(30)};
    }
    .spacer {
      height: #{(letter-gap(30) * 2) + 0%};
    }
  }
  @include desktop-plus {
    $desktop-plus-min: 2%;
    $desktop-plus-max: 98%;
    [class^='letter-'] {
       max-width: $desktop-plus-max !important;
       min-width: $desktop-plus-min  !important;
    }
  }
}

.pattern-04 {
  $min: 3%;
  $max: 100%;
  .internal {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
  [class^='letter-'] {
     width: #{50% - letter-gap(50)};
     max-height: $max !important;
     min-height: $min  !important;
  }
  // inititial position
  .letter-s {
    height: $min;
    // set alignment (right)


  }
  .letter-p {
    height: $max;
    // set alignment (left)
    // margin-left: auto;
    // margin-right: 0;
  }
}

.pattern-05 {
  $min: 5%;
  $max: 100%;
  $margin-scalable: 20;
  $margin-set: 15;
  .internal {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
  }
  [class^='letter-'] {
     height: #{50% - letter-gap(50)};
     max-width: $max !important;
     min-width: $min  !important;
  }
  // inititial position
  .letter-s,
  .letter-p {
    width: $min;
    // alignment (left)
    margin-left: 0;
    margin-right: auto;
    // alignment (right)
    &.rewind {
      margin-left: auto;
      margin-right: 0;
    }
  }
  @include desktop-plus {
    $desktop-plus-min: 2%;
    [class^='letter-'] {
       height: #{50% - letter-gap(50)};
       max-width: $max !important;
       min-width: $desktop-plus-min  !important;
    }
    .letter-s,
    .letter-p {
      width: $desktop-plus-min;
    }
  }
}

.pattern-06 {
  $min: 3%;
  $max: 100%;
  .internal {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
  }
  [class^='letter-'] {
     width: #{50% - letter-gap(50)};
     max-height: $max !important;
     min-height: $min  !important;
  }
  // inititial position
  .letter-s,
  .letter-p {
    height: $min;
  }
  .letter-p {
    // this lockup is fliped (but these are the only bits that dont match up)
    .path-01 {
      bottom: auto !important;
      top: 0;
    }
    .path-02 {
      bottom: 0;
      top: auto !important;
      left: auto !important;
      right: 0;
    }
  }
}


// four-oh-four-page
.pattern-404 {
  $min: 30%;
  $max: 100%;
  .internal {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
  [class^='letter-'] {
    width: calc(33% - #{$rhythm * .5});
    max-height: $max !important;
    min-height: $min  !important;
  }
  // inititial position
  .letter-4 {
    height: $max;
  }
  .letter-0 {
    height: $max;
    align-self: flex-end;
  }
}

// LETTERS

[class^='letter-'] {
  // NOTE height/width is always established in each pattern
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  opacity: .9;
  position: relative;
  > * {
    display: block;
  }
  [class^='path-'] {
    position: absolute;
  }

  [class^='polygon-'] {
    background-color: $letter-colour;
  }
  &.vertical {
    flex-direction: column;
    [class^='polygon-'],
    [class^='blank-'] {
      height: $block-height;
    }
    [class^='path-'] {
      border-left-style: solid;
      border-left-color: $letter-colour;
      @include phone {
        border-left-width: $line-width-phone;
      }
      @include tablet-plus {
        border-left-width: $line-width-tablet-plus;
      }
    }
    .path-01 {
      left: 0;
    }
    .path-02 {
      right: 0;
    }
  }
  &.horizontal {
    flex-direction: row;
    [class^='polygon-'],
    [class^='blank-'] {
      width: $block-height;
    }
    [class^='path-'] {
      border-bottom-style: solid;
      border-bottom-color: $letter-colour;
      @include phone {
        border-bottom-width: $line-width-phone;
      }
      @include tablet-plus {
        border-bottom-width: $line-width-tablet-plus;
      }
    }
    .path-01 {
      bottom: 0;
    }
    .path-02 {
      top: 0;
    }
  }
}

.letter-s {
  &.vertical {
    .path-01 {
      height: calc(50% - 2px);
      top: 1px;
    }
    .path-02 {
      height: calc(50% - 2px);
      bottom: 1px;
    }
  }
  &.horizontal {
    .path-01 {
      width: calc(50% - 2px);
      left: 1px;
    }
    .path-02 {
      width: calc(50% - 2px);
      right: 1px;
    }
  }
}

.letter-p {
  &.vertical {
    .path-01 {
      top: 1px;
      bottom: 1px;
    }
    .path-02 {
      top: 1px;
      height: calc(50% - 2px);
    }
  }
  &.horizontal {
    .path-01 {
      left: 1px;
      right: 1px;
    }
    .path-02 {
      left: 1px;
      width: calc(50% - 2px);
    }
  }
}

.letter-4 {
  display: block;
  [class^='polygon-'] {
    position: absolute;
    top: 0;
    // height: 100%;
    height: 50%;
    @include phone {
      width: $block-height * 1.5 !important;
    }
    @include tablet-plus {
      width: $block-height * 1.2 !important;
    }
  }
  .path-01 {
    left: 0;
    right: 0;
    bottom: 50% !important;
  }
  .path-02 {
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    border-bottom: 0;
    border-left: solid $letter-colour;
    @include phone {
      border-left-width: $line-width-phone;
    }
    @include tablet-plus {
      border-left-width: $line-width-tablet-plus;
    }
  }
  .polygon-01 {
    height: 50%;
    left: 0;
  }
  .polygon-02 {
    right: 0;

  }
}

.letter-0 {
  [class^='polygon-'] {
    width: $block-height * 1.2 !important;
  }
  [class^='path-'] {
    left: 0;
    right: 0;
  }
}
