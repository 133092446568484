::-moz-selection {
  color: $gray;
  background: transparent;
}

::selection {
  color: $gray;
  background: transparent;
}

html {
  font-size: $base-font-size + 0px;
  line-height: $base-line-height;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  // @include fluid-plus {
  //   $increase-font-size: $base-font-size * 1.1428571429;
  //   font-size: $increase-font-size + 0px;
  // }
  &.fake-cursor {
    cursor: none;
    body,
    a,
    .patterns {
      cursor: none;
    }
  }
}

body {
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  background: $almost-black;
  color: $white;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  // opacity: .0000001;
  // .no-js &,
  // .initialised & {
  //   opacity: 1;
  // }
  // @include tablet-portrait-minus {
  //   &.navigation-visible {
  //     overflow-y: hidden;
  //   }
  // }

  // > * {
  //   opacity: 0;
  //   .initialised &,
  //   .no-js & {
  //     opacity: 1;
  //   }
  // }
}

.cursor {
  width: 25px;
  height: 25px;
  background: $almost-white;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  display: none;
  pointer-events: none;
  transition: opacity 100ms, background-color 300ms;
  opacity: .9;
  // only show once we start moving it
  &[style*='top'] {
    display: block;
  }
  .state-cursor-hovering & {
    background: $gray;
    opacity: 1;
  }
  .state-cursor-exit & {
    opacity: 0;
  }
}

#header {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  @include grid-container();
  padding-top: inset();
  // margin-bottom: 0;
  pointer-events: none;
  height: 50vh;
  h1,
  h2 {
    margin: 0;
    position: absolute;
    right: 0;
    left: .1em; // small ajustment to get type to sit aligned
    @include set-font(01);
    // background: rgba(red, .2);
    text-transform: uppercase;
  }
  h1 {
    bottom: auto;
    max-width: 7em;
    position: relative;
    top: -.1em; // small ajustment to get type to sit aligned
  }
  h2 {
    // bottom: -.25em; // small ajustment to get type to sit aligned
    bottom: 0;
    transform: translateY(50%);
  }
}
#content {
  position: relative;
  z-index: 1;
}
#footer {
  @include grid-container($orientation: all);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  .state-pattern-visible & {
    pointer-events: none;
  }
  > * {
    .state-initialised & {
      transition: opacity 800ms;
      pointer-events: none;
    }
  }
  h2 {
    position: absolute;
    bottom: -.15em; // small ajustment to get type to sit aligned
    left: 0;
    @include set-font(01);
    text-transform: uppercase;
    .state-initialised & {
      opacity: 0;
    }
    .state-pattern-visible & {
      opacity: 1;
      transition-delay: 500ms;
    }
  }
  .contact-details {
    @include set-font(00);
    opacity: 0;
    .state-initialised & {
      opacity: 1;
      transition-delay: 500ms;
      pointer-events: all;
    }
    .state-initialised .state-pattern-visible & {
      opacity: 0;
      pointer-events: none;
      transition-delay: 0ms;
    }
    a {
      @include hover-color($gray, 300);
    }
    .address,
    .phone,
    .email,
    .instagram,
    .person {
      display: inline-block;
      white-space: nowrap;
      margin-right: $rhythm;
      p {
        margin: 0;
      }
    }
  }
  @include phone {
    // @#footer
    > * {
      margin: 0;
      padding-bottom: inset($multiple: 3); // iphone X prevent home button overlaying content
    }
    .contact-details {
      max-width: 30em;
    }
  }
}
